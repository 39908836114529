@tailwind base;
@tailwind components;
@tailwind utilities;

/* Animasi Kabel Optik */
.animate-optic-cable {
  position: relative;
  height: 8px;
  background: linear-gradient(90deg, rgba(0, 93, 162, 0.8), rgba(10, 200, 100, 0.8), rgba(255, 0, 0, 0.8));
  animation: optic-cable-movement var(--animation-duration, 4s) linear infinite;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.7), 0 0 25px rgba(255, 255, 255, 0.5);
  border-radius: 5px;
}

/* Keyframes untuk pergerakan kabel optik */
@keyframes optic-cable-movement {
  0% {
    left: -100%;
  }

  100% {
    left: 100%;
  }
}

/* Animasi Pergerakan Partikel */
@keyframes glowing-particles {

  0%,
  100% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }
}

.particles {
  position: absolute;
  top: 50%;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: white;
  opacity: 0;
  animation: glowing-particles 2s infinite;
}

.particles:nth-child(1) {
  left: 10%;
  animation-delay: 0.5s;
}

.particles:nth-child(2) {
  left: 30%;
  animation-delay: 1s;
}

.particles:nth-child(3) {
  left: 50%;
  animation-delay: 1.5s;
}

.particles:nth-child(4) {
  left: 70%;
  animation-delay: 2s;
}

.particles:nth-child(5) {
  left: 90%;
  animation-delay: 2.5s;
}